@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

@font-face {
    font-family: "lato";
    src: local(Lato Regular), url("../fonts/lato/Lato-Regular.woff") format("woff");
    font-weight: 400;
}
@font-face {
    font-family: "latobold";
    src: local(Lato Bold), url("../fonts/lato/Lato-Bold.woff") format("woff");
    font-weight: 700;
}
@font-face {
    font-family: "caveat";
    src: local(Caveat Regular), url("../fonts/caveat/Caveat-Regular.woff") format("woff");
    font-weight: 400;
}
@font-face {
    font-family: "caveatbold";
    src: local(Caveat Bold), url("../fonts/caveat/Caveat-Bold.woff") format("woff");
    font-weight: 700;
}

html {
    /* HTML Basics template */
    font-family: 'lato', sans-serif;
    p {font-size: 15px;}
    hr {
        margin: 0;
        height: 4px;
        border: none;
        background-color: white;
        &.w-30 {
            width: 30px;
        }
        &.w-50 {
            width: 50px;
        }
        &.w-100 {
            width: 100px;
        }
        &.h-5 {
            height: 5px
        }
        &.h-10 {
            height: 10px;
        }
        &.h-16 {
            height: 16px;
        }
        &.center {
            margin: 50px auto 50px auto;
        }
    }
    h1, h2, h3 {font-weight: normal;}

    /* Override semantic */
    body.pushable {
        /*avoid overflow caused by canvas2D overflow-x: initial;*/
        > .pusher {
            overflow: initial;
        }
    }
    body.alr { // Class "alr" to make difference between back (admin pages) and front
        a {
            &:hover {
                &.text-green {
                    color: #afbf07;
                }
                &.text-white {
                    color: #ffffff;
                }
            }
        }
        h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child {
            margin-bottom: 14px;
        }
        .pusher {
            overflow: initial !important;
        }
        .ui.menu .item {
            color:  #535257;
        }
        .ui.menu a:active > i.icon {
            color:  chartreuse;
        }
        .ui.attached.tabular.menu {
            overflow-x: auto !important;
            overflow-y: hidden !important;
            .item {
                color: black !important;
            }
        }
        .ui.grid>.column:not(.row) {
            padding: 0;
        }
        .icon {
            &.phone {
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
        }

        /* Override product */
        #main-container-product {
            width: 100%;
            display: flex;
            margin-bottom: 20px;
            .column {
                @media (max-width: 767px) {
                    padding: 0 !important;
                }
            }
            @media (max-width: 980px) {
                flex-direction: column;
                .column {
                    width: 100% !important;
                }
            }
            .product {
                &-image-alt {
                    display: flex !important;
                    flex-direction: row-reverse;
                    position: relative;
                    width: 65%;
                    .product-image-button {
                        position: absolute;
                        bottom: 1rem;
                        right: 1rem;
                    }
                    .product-realisation-button {
                        position: absolute;
                        bottom: 4.5rem;
                        right: 1rem;
                        padding: 10px 0px;
                        width: 225px;
                    }
                    @media (max-width: 999px) {
                        flex-direction: column;
                    }
                }

            }
        }

        #main-container-product-alt {
            width: 100%;
            display: flex;
            .product__image {
                min-width: 50%;
                height: 100%;
            }
            .product-content {
                height: 100%;
                display: flex;
                flex-direction: column;
                width: 100%;
                &__wrapper {
                    padding: 1rem 1rem 0 1rem;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
                &__description {
                    p {
                        flex-grow: 1;
                    }
                }
                &__title {
                    font-size: 40px;
                    color: #ffffff;
                    background-color: #afbf07;
                    padding: 1.25rem;
                    h1 {
                        margin: 0;
                        font-weight: 700;
                        font-family: "Lato", sans-serif;
                    }
                }
                &__price {
                    p {
                        color: #afbf07;
                        font-family: "latobold", sans-serif;
                        font-size: 36px;
                    }
                }
                &__bottom {
                   align-self: end;
                }
            }
            @media screen and (max-width: 1136px) {
                .product__image {
                    margin-bottom: 25px;
                }
                flex-direction: column;
                padding: 0 20px;
                .product-content {
                    &__bottom {
                        align-self: initial;
                    }
                }
            }
        }


        .tab-menu {
            width: 100%;
            margin: 25px auto;
            .ui.tabular.menu .active.item {
                background: none;
                border-color: #afbf07;
                border-bottom: 5px solid #afbf07;
                border-left: none;
                border-right: none;
                border-top: none;
            }

            .ui.attached.segment {
                border: none;
            }
            @media screen and (max-width: 1136px ){
                padding: 0 25px;
            }
        }

        .block .quality-banner {
            .app-box-container {
                flex-wrap: wrap;
            }
        }

        .app-complement-product {
            display: flex;
            flex-direction: row;
            @media (max-width: 719px) {
                flex-direction: column;
                .app-box {
                    margin: 10px auto;
                }
            }
        }
        .product {
            &-image {
                display: flex !important;
                flex-direction: row-reverse;
                width: 65%;
                position: relative;
                .product-image-button {
                    position: absolute;
                    bottom: 1rem;
                    right: 1rem;
                }
                @media (max-width: 999px) {
                    flex-direction: column;
                }
            }
            &-description {
                width: 35%;
                position: relative;
                &:after {
                    display: block;
                    content: ' ';
                    background-image: url('/build/shop/images/birdtwo.svg');
                    background-repeat: no-repeat;
                    overflow: visible;
                    height: 79px;
                    width: 68px;
                    position: absolute;
                    top: 2.5rem;
                    right: -5.25rem;
                    z-index: 5;
                    @media screen and (max-width: 1100px) {
                        display: none;
                    }
                }
                > div:nth-child(2) {
                    margin-top: 125px;
                    @media screen and (max-width: 1100px) {
                        margin-top: 0;
                    }
                }
                .container-header-product {
                    background-color: #afbf07;
                    position: absolute;
                    right: 0;
                    top: 40px;
                    width: max-content;
                    min-width: 450px;
                    @media screen and (max-width: 1100px) {
                        position: initial;
                        width: 100%;
                        min-width: initial;
                    }
                    &--alt {
                        position: initial;
                        min-width: initial;
                        width: max-content;
                        width: 100%;
                        .header-access {
                            line-height: 1;
                            padding: 20px;
                            width: 100%;
                            height: 100%;
                            background-color: #afbf07;
                            position: relative;
                            .app-product-name {
                                color: #ffffff;
                                margin-bottom: 10px;
                                font-size: 30px;
                                font-family: 'latobold', sans-serif;
                                text-transform: uppercase;
                            }
                        }
                    }
                    .app-header-product {
                        line-height: 1;
                        padding: 20px;
                        width: 100%;
                        max-width: 447px;
                        height: 99px;
                        background-color: #afbf07;
                        position: relative;
                        .app-product-name {
                            color: #ffffff;
                            margin-bottom: 10px;
                            font-size: 30px;
                            font-family: 'latobold', sans-serif;
                            text-transform: uppercase;
                        }
                        .container-short-description {
                            color: #535257;
                            &--product {
                                font-size: 22px;
                                color: #ffffff;
                                font-weight: 400;
                                font-family: "Caveat";
                                text-align: right;
                            }
                        }
                        @media screen and (max-width: 1100px) {
                            height: auto;
                        }
                    }
                    .app-header-product:before {
                        display: block;
                        content: ' ';
                        position: absolute;
                        width: 15px;
                        height: 60px;
                        background-color: #ffffff;
                        left: -0.5rem;
                        bottom: -0.85rem;
                    }

                }
                .app-content-product {
                    color: #535257;
                    padding: 20px 0 20px 20px;

                    &.m-0 {
                        margin-top: 0 !important;
                    }

                    .container-description-product {
                        margin: 20px auto;
                    }

                    .price {
                        p{
                            font-weight: bold;
                            font-size: 32px;
                            color: #afbf07;
                        }
                    }

                    .container-product-bottom {
                        position: absolute;
                        bottom: 0;
                        padding-right: 20px;
                        width: 100%;
                        .caveat {
                            font-size: 1.5rem;
                        }
                        @media screen and (max-width: 1100px) {
                            position: initial;
                            padding-right: 1rem;
                        }
                    }

                    .app-action-button {
                        @media (max-width: 540px) {
                            flex-direction: column;
                        }
                        .app-container-button {
                            @media (max-width: 540px) {
                                margin: 20px auto;
                            }
                            a {
                                color: #ffffff;
                                width: 230px;
                            }
                            a:first-child {
                                background-color: #afbf07;
                                margin-bottom: 10px;
                            }
                            a:last-child {
                                background-color: #2d93ad;
                            }
                        }
                    }
                }
            }
        }
        .app-content-box {
            position: absolute;
            bottom: 0;
            background-color: #ffffff7d !important;
            font-family: 'caveatbold', sans-serif !important;
            width: 100%;
            a {
                color: #666a24 !important;
                z-index: 1000;
            }
        }
        .liste-variante-images {
            display: flex;
            flex-direction: column;
            margin-right: 10px !important;
            @media (max-width: 999px) {
                flex-direction: row;
                margin-right: 0!important;
            }
        }
        .ui.small.images {
            .ui.image {
                width: 110px;
            }
        }
    }

    .container-register {
        width: 100%;
        max-width: 1136px;
        margin: 3rem auto 0;
        margin-bottom: 2rem;
        @media screen and (max-width: 480px){
            max-width: 375px;
            padding: 0 10px;
        }
        @media (min-width: 481px) and (max-width: 1140px) {
            width: 95%;
        }
        .ui.grid {
            margin: 0;
        }
        label {
            font-weight: initial !important;
            color: #535257 !important;
        }
        #wrapper-pro {
            display: none;
        }
        .border-login {
            border: 1px solid #e0e1e2;
            border-radius: 10px;
        }
        .block__login {
            padding: 1rem !important;
            h4 {
                font-family: Caveat, sans-serif !important;
                font-size: 2rem !important;
                margin-bottom: 1rem;
                color: #afbf07;
            }
            .green-alr {
                background-color: #afbf07;
                color: #FFFFFF;
            }
            .ui.dividing.header {
                border: transparent;
            }
            .ui.toggle.checkbox input:checked ~ label::before {
                background-color: #afbf07 !important;
            }
        }
    }

    .app-page-register {
        .ui.padded.segment {
            margin: 2rem 0;
        }
        width: 100%;
        @media screen and (max-width: 1136px){
            padding: 0 1rem;
        }
    }

    .app-page {
        &-product, &-home, &-show {
            width: 100%;
            margin-bottom: 4rem;
            @media screen and (max-width: 1136px){
                padding: 0 1rem;
            }
            @media screen and (max-width: 480px){
                .app-box-container {
                    flex-direction: column;
                }
            }
        }
        &-product {
            h4.ui.horizontal.section.divider.header {
                display: none !important;
            }
            .product__similar {
                 font-size: 36px;
                 color: #afbf07;
                 font-weight: 600;
                 font-family: "Caveat";
                 text-align: center;
                 margin-bottom: 4rem;
                 width: fit-content;
             }
        }
        app-box-container  {
            margin-bottom: 1.5rem;
        }
        &-show {
            @media screen and (max-width: 1136px){
                padding: 0;
            }
        }
        &-la-qualite, &-nos-services, &-made-in, &-les-bons-choix, &-demarches {
            margin: 50px 0 25px 0;
            @media screen and (max-width: 1080px) {
                padding: 1rem;
            }
            h1:first-of-type {
                margin-bottom: 3rem;
            }
            img {
                width: 100%;
            }
        }
    }



    /* CSS Basics */
    .caveat {
        font-family: "caveat" !important;
    }
    .button {
        padding: 10px 30px 10px 30px;
        border-radius: 4px;
        border: 1px solid #ffffff;
        font-size: 15px;
        cursor: pointer;
        &.border-green {
            border: 1px solid #afbf07;
        }
        &.caveat {
            padding: 5px 20px 5px 20px;
            font-size: 24px;
            width: max-content;
            img {
                margin-right: 10px;
            }
        }
    }
    .text {
        &-title {
            text-align: center;
            font-size: 36px;
            color: #afbf07;
            font-family: "caveat";
        }
        &-12 {font-size: 12px;}
        &-15 {font-size: 15px;}
        &-18 {font-size: 18px;}
        &-30 {font-size: 30px;}
        &-36 {font-size: 36px;}
        &-green {color: #afbf07;}
        &-white {color: #ffffff;}
        &-uppercase {text-transform: uppercase;}
        &-bold {font-weight: bold;}
        &-center {text-align: center;}
    }
    .margin {
        &-0 {margin: 0;}
        &-r-10 {margin-right: 10px;}
    }
    .bg {
        &-green {
            background-color: #afbf07 !important;
        }
        &-brown {
            background-color: #926e50 !important;
        }
        &-purple {
            background-color: #946f97;
        }
        &-white {
            background-color: #ffffff;
        }
        &-red {
            background-color: #ed0505;
        }
    }
    .float {
        &-right {
            float: right;
        }
    }
    .relative {position: relative}
    .absolute {position: absolute}
    .flex {
        display: flex !important;
        flex-direction: row;
        &-column {flex-direction: column;}
        &-wrap {flex-wrap: wrap;}
        &-grow {flex-grow: 1;}
        &-align {
            &-center {align-items: center;}
            &-end {align-items: flex-end}
        }
        &-justify {
            &-center {justify-content: center;}
            &-between {justify-content: space-between;}
            &-start {justify-content: flex-start}
        }
        &-center {
            text-align: center;
            .app-link {
                text-decoration: underline;
                color: #535257 !important;
            }
        }
    }
}


/* Template */
.app {
    /* Homepage */
    &-bannner-bloc {
        height: 185px;
        padding: 10px;
        color: #ffffff;
        position: relative;
        cursor: auto;
        flex: 1 185px;
        h3 {
            font-size: 24px;
            margin-bottom: 5px;
        }
        div {
            position: absolute;
            bottom: 10px;
            right: 10px;
            text-align: right;
            hr {
                width: 67px;
                display: inline-block;
            }
            a {
                color: #ffffff;
                display: block;
            }
        }
        .video {
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    /* Template */
    &-flex-1 {flex: 1;}
    &-icon-floating {
        position: fixed;
        top: 300px;
        right: -133px;
        z-index: 9;
        transition: right 0.5s ease-out;
        &:hover {
            right: 0px;
        }
        @media screen and (max-width: 480px){
            display: none;
        }
        > div {
            background-color: #666a24;
            margin: 5px 0;
            padding: 5px;
            color: #ffffff !important;
            p {
                text-align: right;
                font-family: "Roboto";
            }
            img {
                width: 32px;
                height: 32px;
                margin-right: 20px;
            }
            a {
                color: #FFFFFF !important;
                text-decoration: none !important;
            }
        }
    }
    &-rect {
        position: absolute;
        &.left {
            &-1 {
                left: 130px;
            }
            &-2 {
                left: 0;
            }
        }
        &.right {
            &-1 {
                right: 190px;
            }
            &-2 {
                right: 210px;
            }
        }
        &.green {
            background-color: #afbf07;
            bottom: -25px;
        }
        &.white {
            background-color: #ffffff;
            bottom: 0;
            left: 50px;
        }
        &.vertical {
            width: 15px;
            height: 40px;
        }
        &.horizontal {
            width: 100px;
            height: 16px;
            &.pos-1 {
                left: 12%;
            }
            &.pos-2 {
                right: 17%;
            }
        }
    }

    /* Page */
    &-page {
        background: none;
        box-shadow: none  ;
        border: none;
        max-width: 1136px;
        width: 100%;

        // CSS - Page : Listes des conseils techniques + Page : Conseil technique
        &-conseils-technique,
        &-conseil-technique {
            margin-top: 50px;
            table {
                margin: 50px 30px 0 30px;
                tr {
                    display: flex;
                    td {
                        h2 {
                            text-transform: uppercase;
                        }
                    }
                }
                td {
                    padding: 20px;
                    hyphens: auto;
                    &:first-child {
                        flex: 1;
                        padding-top: 10px;
                        margin-right: 90px;
                        h2 {
                            color: #2d93ad;
                            font-weight: normal;
                        }
                    }
                    &:last-child {
                        flex: 1;
                        background-color: #afbf07;
                        border-radius: 10px;
                        color: #ffffff;
                        height: max-content;
                        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
                        h2 {
                            display: flex;
                            align-items: center;
                            &:before {
                                margin-right: 20px;
                            }
                        }
                    }
                    h2 {
                        font-size: 24px;
                    }
                }
                &:nth-child(2) {
                    margin-top: 50px;
                    td {
                        background-color: #926e50;
                        margin: 0 5px;
                        color: #ffffff;
                        padding: 20px 10px;
                        position: relative;
                        width: 270px;
                        height: 280px;
                        > *:not(img) {
                            margin-left: 20px;
                        }
                        h2 {
                            font-size: 18px;
                            margin-bottom: 5px;
                        }
                        a {
                            margin-top: 10px;
                            color: #ffffff;
                            display: inline-block;
                        }
                        img {
                            position: absolute;
                            bottom: 10px;
                        }
                    }
                }
            }
            .app-background-image {
                &.thumbnail,
                &.picto {
                    display: none;
                }
            }
        }


        .app-box-container {
            margin: 60px auto 20px auto;
            position: relative;
            width: 100%;
            max-width: 1136px;

            .app-small-box {
                color: #ffffff;
                font-size: 20px;
                padding: 25px;
                position: relative;
                width: 100px;
                max-width: 270px;
                height: 150px;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
                flex: 1 200px;
                display: flex;
                &:nth-child(1) {
                    background-color: #926e50;
                }
                &:nth-child(2) {
                    background-color: #afbf07;
                }
                &:nth-child(3) {
                    background-color: #2d93ad;
                }
                &:nth-child(4) {
                    background-color: #946f97;
                }
                &:nth-child(1n+5) { // SELECTOR TO DELETE
                    display: none;
                }
                hr {
                    margin: 10px 0 5px 0;
                }
                > div {
                    &:first-child {
                        position: absolute;
                        z-index: 10;
                    }
                }
                .app-background-image {
                    background-size: auto;
                    background-repeat: no-repeat;
                    background-position: bottom 10px right 30px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                }
                @media (max-width: 999px) {
                    margin: 20px auto;
                    max-width: 300px;
                    padding: 20px;
                    min-width: 270px;
                }
            }
            @media screen and (max-width: 480px) {
                .text-title {
                    padding: 0 15px;
                }
                .bottom-title {
                    padding: 0 15px;
                }
                .bottom-content {
                    padding: 0 15px;
                }
                img {
                    width: 80%;
                    margin-bottom: 25px;
                }
            }

            .app-large-box {
                width: 100%;
                div {
                    padding: 0 15px;
                }
                @media (max-width: 1100px) {
                    flex-direction: column;
                    text-align: justify;
                }
            }
            .top-content {
                @media screen and (max-width: 1100px) {
                    flex-wrap: wrap;
                    img {
                        margin: 2rem auto;
                    }
                }
            }
            @media (max-width: 1100px) {
                display: flex;
                flex-wrap: wrap;
            }
        }
        .app-container-articles {
            margin-top: 50px;
            padding: 20px;
            position: relative;
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
            }
            &:before {
                background-size: cover;
                background-repeat: no-repeat;
                z-index: -2;
            }
            &:after {
                background-color: #212121;
                opacity: 0.8;
                z-index: -1;
            }
            h2 {
                margin: 20px auto 50px auto;
                width: max-content;
            }
        }

        // CSS - Page : Liste des realisations + Page : Liste des actualites
        .app-box-list {
            display: flex;
            flex-direction: row;
            @media (max-width: 740px) {
                flex-direction: column;
            }
            .ui.card {
                border-radius: 0px;
                margin: 0 5px;
                color: #ffffff;
                font-family: 'latobold', sans-serif;
                padding: 5px 10px;
                position: relative;
                width: 270px;
                height: 280px;
                hr {
                    margin-top: 10px;
                    position: relative;
                    z-index: 9;
                }
                a {
                    margin-top: 10px;
                    color: #ffffff;
                    display: inline-block;
                }
                .content {
                    flex-grow: 0;
                }
                .image {
                    height: 100%;
                    max-height: 160px;
                    background: none;
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    right: 10px;
                    > div {
                        background-size: cover;
                        height: inherit;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
            }
        }

        // Page : Liste des conseils techniques
        &-conseils-technique, &-actus {
            .app-box-container {
                .app-small-box {
                    width: 100%;
                    flex: 1 0 21%;
                    min-height: 280px !important;
                    max-width: 270px;
                    background-color: #926e50 !important;
                    .app-background-image {
                        top: auto;
                        bottom: 10px;
                        left: 10px;
                        right: 10px;
                        &.thumbnail {
                            display: block;
                            width: 250px;
                            height: 180px;
                        }
                    }
                }
                .app-small-box:nth-child(n+5) {
                    display: flex;
                }
            }
        }

        // Page : Conseil technique
        &-conseil-technique {
            max-width: initial;
            margin: 50px 0 0 0;
            table {
                &:nth-child(1) {
                    width: 100%;
                    margin: 0;
                }
                &:nth-child(2) {
                    margin: 75px auto auto;
                    tr {
                        margin: 10px;
                        td {
                            background-color: initial;
                            color: #000000;
                        }
                    }
                }
            }
            .app-small-box {
                .app-background-image {
                    &.picto {
                        display: block;
                    }
                }
            }
        }
        &-nos-realisations {
            margin-top: 50px;
            .realisation-content {
                margin-top: 50px;
            }
            .menu-filter {
                padding: 50px 30px 50px 20px;
                text-transform: uppercase;
                background-color: #f2f2f2;
                h3 {
                    margin-top: 20px;
                }
                .container {
                    display: block;
                    position: relative;
                    padding-left: 30px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 15px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    input {
                        position: absolute;
                        opacity: 0;
                        &:checked ~ .checkmark {
                            background-color: #afbf07;
                            border: none;
                            &:after {
                                display: block;
                            }
                        }
                    }
                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 18px;
                        width: 18px;
                        background-color: white;
                        border: 2px solid #818181;
                        border-radius: 4px;
                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                            left: 7px;
                            top: 4px;
                            width: 5px;
                            height: 10px;
                            border: solid white;
                            border-width: 0 3px 3px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            .app-box-list {
                width: 840px;
                height: max-content;
                .ui.card {
                    height: 250px;
                    margin: 0 5px 10px 5px;
                    padding: 0;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
                    img {
                        cursor: pointer;
                        &.main-image {
                            height: 200px;
                            width: 100%;
                            max-height: initial;
                            bottom: 0;
                        }
                        &.zoom-in {
                            bottom: 5px;
                            right: 5px;
                            width: 37px;
                            height: 37px;
                        }
                    }
                }
            }
            @media screen and (max-width: 500px) {
                .realisation-content {
                    flex-direction: column;
                    align-items: center;
                }
                .app-box-list {
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-top: 25px;
                }
            }
        }

        // Page : Listes des actualites
        &-actus {
            .app-header-actus {
                text-align: center;
                color: grey;
            }
            .date-creation {
                font-size: 12px;
                margin-bottom: 10px;
            }
        }

        .header {
            color: #afbf07 !important;
            text-align: center;
            text-transform: uppercase !important;
            border-bottom: none !important;
            margin: 20px 0 !important;
        }
    }

    /* Lightbox */
    &-lightbox {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        display: none;
        &-block {
            width: 1200px;
            height: 800px;
            border-radius: 4px;
        }
        &-content {
            width: 800px;
            height: 600px;
            h2 {
                top: -57px;
                left: 5px;
            }
        }
        &-image {
            img {
                width: 100%;
            }
        }
        &-buttons {
            position: absolute;
            width: 100%;
            padding: 15px 20px;
            bottom: 0;
        }
        &-close {
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
    }

    /* Autres */
    &-box {
        width: 120px;
        height: 120px;
        padding: 20px;
        background-color: #f2f2f2;
        text-align: center;
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
        > div:first-child {
            line-height: 30px;
        }
    }
    &-pres-categorie {
        color: #ffffff;
        font-size: 28px;
        line-height: 1.2;
        text-transform: uppercase;
        max-width: 300px;
        margin-right: 20px;
        width: 100%;
        padding: 20px;
        hr {
            margin-left: 0;
            width: 60px;
        }
    }
    &-savoir-plus {
        color: #535257 !important;
        margin-top: 20px;
        text-decoration: underline;
    }
}
#app-main-container {
    display: flex;
    flex-direction: column;
    max-width: 1136px;
    width: 100%;
    @media screen and (max-width: 480px) {
        max-width: 480px;
    }
    margin: 0 auto;
    #app-container-article {
        .header-article {
            background-color: #2d93ad;
            height: 250px;
            text-transform: uppercase;
            text-align: center;
            padding: 20px 0;
            font-size: 15px;
            .separateur {
                height: 5px;
                background-color: #ffffff;
                width: 60px;
                margin: 20px auto;
            }
            > div:first-child {
                margin-top: 20px;
                color: #ffffff;
            }
            .app-title {
                color: #ffffff;
                font-size: 36px;
                font-family: 'latobold', sans-serif;
                margin: 10px auto;
                line-height: 1;
                width: 50%;
            }
        }
        .app-content-article {
            background-color: #ffffff;
            padding: 20px;
            width: 80%;
            margin: -60px auto 0 auto;

        }
    }
    .app-account {
        [class*="four wide"].column {
            width: 23% !important;
        }
        .ui.grid {
            margin-top: initial;
            margin-bottom: initial;
            margin-left: initial;
            margin-right: initial;
        }
        .ui.two.column.stackable.grid {
            justify-content: space-between;
            > div {
                width: 49%;
            }
        }
        #sylius-shipping-address {
            padding-left: 1rem;
        }
        @media screen and (max-width: 767px) {
            [class*="four wide"].column {
                width: 100% !important;
            }
            .ui.vertical.menu {
                width: 100% !important;
            }
        }
    }

    .account-container {
        margin: 1.25rem 0;
        .login-title {
            font-size: 2.5rem;
            color: #afbf07;
            margin-bottom: 0.5rem;
        }
        .sub-login {
            height: 1rem;
            background-color: #afbf07;
            max-width: 70%;
        }
        @media screen and (max-width: 1100px) {
            .ui.header {
                text-align: center !important;
            }
        }
        a:active {
            i { color: #afbf07 }
        }
    }

    #projects-three {
        .project-desc__wrapper {
            height: 100%;
            align-items: center;
        }
        .project__desc {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: center;
            a {
                font-weight: bold;
            }
        }
        .actions {
            text-align: right;
            vertical-align: middle;
               a {
                   color: grey;
               }
            i {
                font-size: 1.5em;
            }
            .delete {
                background-color: #d5d3d3;
                background-color: #d5d3d3;
                border-radius: 9999px;
                height: 2.5em;
                display: inline-flex;
                width: 2.5em;
                justify-content: center;
                align-items: center;
                i {
                    margin: 0;
                }
            }
        }
        .btn-rounded {
            background-color: #afbf07 !important;
            color: white;
            text-align: center;
            padding: 1rem 1.5rem !important;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .find-project {
        color: #535257;
        font-size: 12px;
    }

    .app-container-rating {
        color: #ffffff;
        position: fixed;
        right: 0px;
        top: 30%;
        z-index: 1000;
        background-color: #666a24;
        padding: 10px;
        writing-mode: tb;
        transform: rotate(-180deg);
        pointer-events: none;

        .app-note-rating {
            display: flex;
            transform: rotate(-180deg);
        }
    }
    .app-banner {
        height: 370px;
        position: relative;
        width: 100%;
        @media screen and (max-width: 1140px){
            display: flex;
            flex-direction: column;
            height: fit-content;
            align-items: center;
            margin-top: 3px;
            .bannner__wrapper {
                width: 100%;
                max-width: 766px;
            }
        }
        @media screen and (max-width: 480px) {
            .bannner__wrapper {
                justify-content: center;
            }
            .app-bannner-bloc {
                margin: 15px;
                width: 100%;
            }
        }
        .glide {
            height: 370px;
            position: relative;
            width: 100%;
            max-width: 766px;
            &__track,
            &__slides,
            &__slide {
                height: 100%;
            }
            &__arrows {
                opacity: 0;
            }
            &__bullets {
                bottom: 10px;
                left: initial;
                right: 15px;
                transform: initial;
                .glide__bullet {
                    width: 15px;
                    height: 15px;
                }
            }
            @media screen and (max-width: 480px) {
                margin-bottom: 25px;
                .img-banner {
                    background-size: contain;
                    background-position: center;
                    background-color: #afbf07;
                }
            }
        }
        .img-banner {
            background-size: 766px 100%;
            background-position: left;
            background-repeat: no-repeat;
            flex-grow: 1;
            position: relative;
            background-size: 80%; //TODO adjust source images to fit ratio so we'll use cover instead
        }
        .bird {
            @media screen and (max-width: 1100px) {
                display: none;
            }
            position: absolute;
            &-left {
                left: -100px;
                top: 30px;
            }
            &-right {
                right: -80px;
                bottom: 20px;
            }
        }
    }
    .mobile {
        position: fixed;
        bottom: 5rem;
        right: 1.5rem;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #926e50;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            margin-top: 5px;
        }
        @media (min-width: 1080px) {
            display: none
        }
    }
    .app-container {
        margin: 60px auto;
        h2 {margin-bottom: 60px;}
        .app-logo-mdfr {
            max-height: 230px;
            max-width: 230px;
            margin: 0 auto;
        }

        .app-iframe {
            max-width: 100%;
            iframe {
                width: 100%;
                height: 500px;
            }
        }
        .app-container-presentation {
            > div {
                p:last-child {margin-bottom: 40px;}
            }
        }
        .app-description-video {margin-top: 20px;}
    }
    #app-login-container{
        max-width: 1136px;
        width: 100%;
        display: flex;
        .login-container {
            margin-top: 2rem;
            margin-bottom: 3rem;
            margin-left: 0;
            margin-right: 0;
            flex-grow: 2;
            .login-title {
                font-size: 2.5rem;
                color: #afbf07;
                margin-bottom: 0.5rem;
            }
            .sub-login {
                height: 1rem;
                background-color: #afbf07;
                max-width: 70%;
            }
            @media screen and (max-width: 1100px) {
                .ui.header {
                    text-align: center !important;
                }
            }
        }
    }

    .cart-container {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 2rem;
        color: #535257;
        .cart-content__wrapper {
            padding: 0 1rem 0 0;
        }
        h2 {
            font-family: Caveat, sans-serif;
            font-size: 1.5rem !important;
            color: #afbf07;
        }
    }
}

/* Top bar */
#menu {
    background-color: #d8d7da;
    color: #535257;
    height: 45px;
    @media screen and (max-width: 1080px) {
        display: none !important;
    }
    .top_bar_icon {
        i {
            font-size: 23px;
            color: #ffffff !important;
        }
    }

    > .app-container-menu {
        width: 100%;
        @media (min-width: 999px) and (max-width: 1050px) {
            font-size: 14px;

        }
        .app-left-menu {
            flex-grow: 1;
            margin-left: 40px;
        }
        > .app-contact {
            background-color: #afbf07;
            padding: 0 10px;
            height: 45px;
            margin-right: 0;
            @media (max-width: 1050px) {
                margin: auto;
            }
            > a {
                color: #FFFFFF !important;
                text-transform: uppercase;

                > i {
                    margin-right: 10px;
                }
            }
        }
        .app-center-top_bar {
            flex-grow: 1;
        }
        > div {
            line-height: 45px;

            > .app-medium {
                font-size: 21px;
            }

        }
    }

}

/* Header + Menu */
#app-header {
    margin: 0 auto;
    width: 1136px;
    .app-logo-home {
        width: 30%;
        margin-right: 100px;
    }
    i.icon {
        color: #afbf07;
        font-size: 21px;
        margin-right: 20px;
    }
    .default.text {
        color: #535257;
    }
    .override-main-menu {
        background: none;
        box-shadow: none;
        border-radius: 0;
        @media (max-width: 971px) {
            .app-flex {
                display: flex;
                flex-direction: column;
            }
        }
    }
    .menu {
        @media (max-width: 971px) {
            display: flex;
            flex-direction: column;
        }
        .item {
            flex: 1;
            display: flex;
            justify-content: center;
            padding: 0;
            &:hover {
                background-color: #afbf07;
                color: #ffffff !important;
                > a,
                .default.text {
                    color: #ffffff;
                }
            }
            &-primary {
                background-color: #afbf07;
                flex: 2;
                > a {
                    font-size: 20px;
                }
                img {
                    width: 97px !important;
                    height: 97px;
                }
            }
            &.color-primary {
                color: #afbf07;
            }
        }
        .dropdown {
            &.configure-alr {
                .caveat {
                    color: white !important;
                }
                > .item {
                    width: max-content;
        }
            }
            > .item {
                padding: 0;
                &:before {display: none;}
            }
            .sub-menu {
                width: 500px;
                padding: 20px;
                display: flex !important;
                flex-direction: row !important;
                background-color: #f4f4f4;
                .flex-column {
                    width: 100%;
                    .item {
                        justify-content: flex-start;
                        padding: 9px 10px;
                        &:before {display: none;}
                        &.text {
                            &-light-green {color: #afbf07;}
                            &-brown {color: #926e50;}
                            &-blue {color: #2d93ad;}
                        }
                    }
                    img {
                        width: auto;
                        &.shift {
                            position: relative;
                            right: -25px;
                        }
                    }
                }
            }
        }
    }
    .header-container {
        margin-top: 2rem !important;
    }
    .banner-pos {
        right: 1rem;
        top: -2rem;
        background-color: #afbf07;
        width: 720px;
        height: 63px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #FFFFFF;
        padding: 0 1.5rem;
        overflow: hidden;
        background-image: url("/build/shop/images/corner.png");
        background-repeat: no-repeat;
        background-position: left bottom ;
        background-position-y: 41px;
        @media screen and (max-width: 480px){
            display: none;
        }
        p {
            display: block;
            position: absolute;
            overflow: hidden;
            animation: marquee 5s linear infinite;
            left: 0;
            width: 100%;
            text-align: right;
            font-size: 20px;
        }
    }
    .banner-pos-bird {
        display: block;
        content: ' ';
        background-image: url('/build/shop/images/origami_banner.svg');
        background-repeat: no-repeat;
        overflow: visible;
        height: 38px;
        width: 38px;
        position: absolute;
        top: -0.75rem;
        right: -3.25rem;
        z-index: 5;
    }
    @keyframes marquee {
        0% { left: 0; }
        100% { left: -100%; }
    }
    .app-right-header {
        flex-grow: 1;
        padding: 20px;
        padding-bottom: 0px;
        margin: auto;
        margin-bottom: 10px;

        > div {
            > div {
                margin-right: 20px;
                height: 40px;
                line-height: 40px;
                text-align: left;

                &:last-child {
                    margin-right: 0;
                }

                @media (max-width: 1200px) {
                    margin-right: 20px;
                }
            }
        }
    }
    .input {
        > .app-input-search {
            border: none;
            border-bottom: 1px solid #dcdcdc;
            border-radius: 0;
            background-color: transparent;
            margin-left: 10px;
        }
    }
    a:not(.text-white) {
        color: #535257;
    }
}

/* Page : Inter Gamme */
.inter-gamme {
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    @media screen and (max-width: 1100px) {
        flex-wrap: wrap;
        justify-content: center;
        padding: 15px;
    }
    > div:only-child {
        margin: 0 auto;
    }
    > div {
        position: relative;
        padding: 10px 10px 20px 10px;
        margin: 10px;
        max-width: 560px;
        min-width: 280px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        flex: 1 0 45%;
        &:nth-child(odd) {
            background-color: #666a24;
        }
        &:nth-child(even) {
            background-color: #afbf07;
        }
        > span {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 22px;
            @media screen and (max-width: 480px) {
                top: 10px;
            }
        }
        h2 {
            font-size: 28px;
            margin: 30px 0 5px 20px;
        }
        p {
            margin: 20px;
        }
        a {
            margin: 10px 5px 10px 5px;
            &.caveat {
                border: 1px solid white;
                font-size: 24px;
            }
        }
        hr {
            margin: 0 0 20px 20px;
        }
        .bloc-image {
            > img {
                width: 100%;
                height: 395px;
                aspect-ratio: 1 / 1;
                object-fit: cover;
            }
            @media screen and (max-width: 480px) {
                >img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .card-btn__wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: right;
            margin-top: auto;
            @media screen and (max-width: 500px){
                justify-content: center;
            }
        }
    }
}
.choose-product {
    img {
        width: 550px;
    }
    &-description {
        padding: 0 30px;
        @media screen and (max-width: 480px){
            padding: 0;
        }
        h2 {
            color: #2d93ad;
        }
        .btn__wrapper {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -30px;
            justify-content: end;
            a {
                margin: 10px;
            }

        }
    }

    @media screen and (max-width: 1120px){
        flex-direction: column !important;
        align-items: center;
        padding: 1rem;
        .choose-product-description {
            margin-top: 1.25rem;
        }
        img {
            width: 80%;
        }
        .btn__wrapper {
            justify-content: start;
            margin: 0 -10px;
        }

    }
    @media screen and (max-width: 480px){
        .btn__wrapper {
            justify-content: center;
            margin: 0 -10px;
        }
    }
}

/* PANIER / Cart */

.cart-header {
    margin: 0 !important;
    hr {
        height: 14px;
        background: #afbf07;
        width: 70%;
    }
}

.cart-title {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.cart-origami {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-right: 1rem;
}

.cart-content {
    padding: 1rem;
    #sylius-cart-update, #sylius-save, .pay-btn {
        text-align: center;
        background-color: #afbf07 !important;
        padding: 1rem 1.5rem !important;
        color: white;
    }
}

#sylius-cart-items {
    .sylius-product-name, .sylius-product-variant-code {
        font-size: 13px;
        max-width: 260px;
        white-space: normal;
        color: #a7a4a4 !important;
    }
}

.pay-btn {
    text-align: center;
    background-color: #afbf07 !important;
    padding: 1rem 1.5rem !important;
    color: white;
}

/* Footer */
#footer {
    background-color: #d8d7da;
    color: #535257;
    margin-top: 0 !important;
    @media screen and (max-width: 480px) {
        padding: 4em 4em 6em 4em !important;
    }
    .ui {
        > .newsletter-input {
            > input {
                border-radius: 0 !important;
            }
        }
        > .newsletter-button {
            > .button {
                border-radius: 0;
                background-color: #87ab75;
                color: #FFFFFF;
                height: 38px;
            }
        }
    }
    .ui.inverted.link.list {
        a.item {
            color: #535257;
            font-size: 13px;
            margin: 10px 0;
        }
    }
    .ui.inverted.header {
        text-transform: uppercase;
    }
    .footer_copyright {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #535257;
        text-align: center;
        padding: 10px;
        p,
        a {
            color: white;
            font-size: 13px;
        }
    }
}

/* Autres */
#products, #app-main-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    .product__wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        @media screen and (max-width: 1100px) {
            justify-content: center;
        }
    }
    .sylius-product-name {
        color: white;
    }
    .vignette {
        margin: 0.5rem auto;
        max-width: 260px;
        display: flex;
        flex-direction: column;
        .content {
            padding: 1rem;
            position: relative;
            flex-grow: 1;
            min-height: 68px;
            overflow: visible;
            text-overflow: ellipsis;
        }
        hr {
            position: absolute;
            z-index: 1;
            bottom: -0.25rem;
        }
    }
    .roof-type {
        .vignette {
            max-width: 160px;
        }
    }
}
.associated-product {
    justify-content: space-evenly;
    @media screen and (max-width: 1024px) {
        justify-content: center;
    }
}
.vignette-shop {
    position: relative;
    .hide {
        display: none;
        pointer-events: none;
    }
    :hover + .hide  {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 260px;
        height: 260px;
        background-color: rgba(43, 42, 42, 0.45);
    }
}

.autres-produits {
    height: 300px;
    background-color: #afbf07;
    margin: 0 -1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
    @media (max-width: 905px) {
        flex-direction: column;
        height: 100%;
        .app-pres-categorie {
            margin: auto;
        }
    }


    .app-list-product {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        @media (max-width: 905px) {
            margin: auto;
            flex-wrap: wrap !important;
            justify-content: center;
        }
        .column {
            max-width: 250px;
            width: 100% !important;
        }
        .card {
            height: 230px;
            .image {
                height: 100%;
                img {
                    height: 100%;
                }
            }
        }
    }
}
.block {
    max-width: 1136px;
    width: 100%;
    margin: 0 auto;
    .app-title {
        color: #afbf07;
        text-align: center;
        font-size: 20px;
        font-family: 'latobold', sans-serif;
        text-transform: uppercase;
        margin: 30px 0;
    }
    .app-small-box {
        color: #ffffff;
        font-size: 20px;
        padding: 25px;
        position: relative;
        width: 270px;
        height: 150px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
        &:nth-child(1) {
            background-color: #926e50;
        }
        &:nth-child(2) {
            background-color: #afbf07;
        }
        &:nth-child(3) {
            background-color: #2d93ad;
        }
        &:nth-child(4) {
            background-color: #946f97;
        }
        &:nth-child(1n+5) { // SELECTOR TO DELETE
            display: none;
        }
        hr {
            margin: 10px 0 5px 0;
        }
        > div {
            &:first-child {
                position: absolute;
                z-index: 10;
            }
        }
        .app-background-image {
            background-size: auto;
            background-repeat: no-repeat;
            background-position: bottom 10px right 30px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
        @media (max-width: 999px) {
            margin: 20px auto;
            max-width: 300px;
            min-width: 270px;
        }
    }
}
.custom-img {
    background-size: cover;
    height: 150px;
    width: 235px;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* Configurateur */

.app-page {
    &.home {
        margin-top: 0px;
    }
    &.app-page-espace-pro {
        padding-top: 50px;
        /* Slideshow container */
        .slideshow-container {
            max-width: 1000px;
            position: relative;
            margin: auto;
        }

        /* Next & previous buttons */
        .prev, .next {
            cursor: pointer;
            position: absolute;
            top: 50%;
            width: auto;
            margin-top: -22px;
            padding: 16px;
            color: white;
            font-weight: bold;
            font-size: 18px;
            transition: 0.6s ease;
            border-radius: 0 3px 3px 0;
            user-select: none;
        }

        /* Position the "next button" to the right */
        .next {
            right: 0;
            border-radius: 3px 0 0 3px;
        }

        /* On hover, add a black background color with a little bit see-through */
        .prev:hover, .next:hover {
            background-color: rgba(0,0,0,0.8);
        }

        /* Caption text */
        .text {
            color: #f2f2f2;
            font-size: 15px;
            padding: 8px 12px;
            position: absolute;
            bottom: 8px;
            width: 100%;
            text-align: center;
        }

        /* Number text (1/3 etc) */
        .numbertext {
            color: #f2f2f2;
            font-size: 12px;
            padding: 8px 12px;
            position: absolute;
            top: 0;
        }

        /* The dots/bullets/indicators */
        .dot {
            cursor: pointer;
            height: 15px;
            width: 15px;
            margin: 0 2px;
            background-color: #bbb;
            border-radius: 50%;
            display: inline-block;
            transition: background-color 0.6s ease;
        }

        .active, .dot:hover {
            background-color: #717171;
        }

        /* Fading animation */
        .fade {
            animation-name: fade;
            animation-duration: 1.5s;
        }

        @keyframes fade {
            from {opacity: .4}
            to {opacity: 1}
        }
    }
    &.app-page-create-projet {
        margin: 0;
        margin-bottom: 4rem;
        background: linear-gradient(
                        to bottom,
                        #afbf07,
                        #afbf07 382px,
                        transparent 1rem,
                        transparent 100%
        );
        ;
        padding-top: 50px;
        max-width: 100%;
        min-height: 382px;
        position: relative;
        .text-title {
            display: none;
        }
    }
    &-nos-realisations {
        margin-bottom: 4rem;
    }

}

.card-info {
    border-radius: 10px;
    background-color: #afbf07;
    padding: 1rem;
    p {
        color: white !important;
    }
    .gamme-info {
        display: block;
        width: 170px;
        height: auto;
        margin-right: 0.625rem;
    }
    .gamme-info-title {
        font-family: Caveat, sans-serif !important;
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 0.625rem !important;
    }
}
.container-conf {
    max-width: 1136px;
    width: 100%;
    margin: 0 auto;
    color: white;
    position: relative;
    &__bg {
        background-color: #afbf07;
        position: absolute;
        height: 500px;
        width: 100%;
        z-index: 1;
    }

    ul {
        margin-left: 0;
        padding-left: 2rem;
        padding-right: 2rem;
        li {
            list-style: none;
        }
        @media screen and (max-width: 750px) {
            margin-bottom: 0;
            padding: 0 1rem;
            overflow-x: scroll;
            li {
                margin: 0 1rem;
                height: 38px;
            }
        }
        @media screen and (max-width: 480px) {
            padding: 0 0.5rem;
        }
    }
    .configurator-menu--disabled {
        pointer-events: none;
    }
    .main-image {
        display: block;
        max-width: 260px;
    }
    .content {
        position: relative;
        padding: 1em 1em;
        line-height: initial;
        max-height: 52px;
        h2 {
           font-size: 18px !important;
            color: white;
        }
        hr {
            position: absolute;
            bottom: -5px;
            z-index: 1;
        }
    }
    .vignette {
        margin-top: 5px;
        margin-bottom: 5px;
        background-color: #b9b8bd;
        .zoom-in {
            z-index: 1;
            right: 10px;
            bottom: 10px;
        }
    }
    .three-s {
        max-height: 630px;
        max-width: 555px;
        position: absolute;
        right: 0;
        top: 188px;
        z-index: 10;
        img, canvas {
            max-width: 555px;
            width: 100%;
        }
        @media screen and (max-width: 809px) {
            width: 90%;
        }
    }
    .three-s-desc {
        padding-top: 0.25rem;
        img { display: block}
        p {
            text-align: right;
            color: white;
            font-size: 15px;
            font-weight: bolder;
            text-transform: uppercase;
            &.ttc {
                font-size: 36px;
                font-weight: bold;
            }
        }
    }
    .three-s-n {
        color: #FFFFFF;
        font-size: 12px;
        line-height: 1;
        margin-left: 1rem;
    }
    .roof-type {
        .main-image {
            max-width: 160px;
        }
    }
}

.card-input-element {
    display: none;
}

.card-input-element:checked + .vignette {
    background-color: #afbf07;
}

use-type, roof-type, gamme-type {
    label {
        cursor: pointer;
    }
}

.tabcontent {
    width: 100%;
    background-color: #F9FAFB;
    height: 100%;
    color: black;
    &-head {
        padding: 2.5rem 1.75rem 0 1.75rem;
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }
    }
    &-headalt {
        padding: 2.5rem 0 0 1.75rem;
        height: 100%;
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 1024px) {
            flex-direction: column;
            padding: 2.5rem 1rem 0 1rem;
        }
    }
    &__login {
        h2 {
            color: #2d93ad;
            font-weight: normal;
            text-transform: uppercase;
        }
        p {
            display: inline-block;
            font-size: 15px;
            color: #535257;
        }
        .login__header {
                display: flex;
                flex-wrap: wrap;
                padding: 2.5rem 0.75rem 0 0.75rem;
                color: #535257;
            > div {
                padding: 0 1rem;
                flex: 1;
            }
            #error {
                color: red;
                font-weight: bold;
            }
            #errorDptContact {
                color: red;
                font-weight: bold;
            }
            .hide {
                display: none;
            }
            .mobile-msg {
                display: none;
                @media screen and (max-width: 480px) {
                    display: flex;
                    padding: 1rem;
                    font-size: 20px;
                    font-wieght: bold;
                    border: 4px solid #afbf07;
                    border-radius: 5px;
                }
            }
            label {
                font-size: 15px;
            }
            input {
                border: 1px grey;
                padding: 0.75rem;
                border-radius: 5px;
                color: grey;
                margin-left: 1rem;
                box-shadow: 0 0 1px grey;
            }
            a {
                color: white;
                font-weight: bold;
                text-transform: uppercase;
            }
            @media screen and (max-width: 750px) {
                flex-direction: column;
            }
        }
        .login__content {
            padding: 1.5rem 0.5rem;
            h2 {
                margin-left: 1.25rem;
            }
            .project__wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
            }
        }
    }
    &--left {
        width: 50%;
        position: relative;
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        align-content: space-between;
        @media screen and (max-width: 1024px) {
            width: 100%;
            padding-right: 1.75rem;
        }
        fieldset {
            border: none;
            input[type="number"] {
                -moz-appearance: textfield;
                width: 50px;
                text-align: center;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
        .door-info {
            font-size: 12px;
        }
        .tabcontent-desc{
            padding: 2.5rem 1.75rem 0 1.75rem;
        }
        .txt-cfg  {
            display: inline-block;
            font-size: 15px;
            color: #535257;
            font-weight: 700;
            font-family: "Lato";
            &.qty {
                margin-right: 2.5rem;
            }
        }
        .pignon {
            @media screen and (max-width: 750px) {
                height: 80px;
            }
        }
        p {
            display: inline-block;
            font-size: 15px;
            color: #535257;
        }
        a {
            display: inline-block;
            font-size: 15px;
            color: #535257;
            padding: 5px;
            text-decoration: none;
            .c-active {
                border: solid 2px #535257;
                border-radius: 10px;
            }
        }
        a.btnAddWindows{
            padding: 10px 30px 10px 30px;
            border-radius: 4px;
            border: 1px solid #ffffff;
            font-size: 15px;
            cursor: pointer;
            color: white;
        }

        .doors {
            text-align: left;
        }
        h2 {
            color: #2d93ad;
            font-weight: normal;
            text-transform: uppercase;
        }
        .btn-r {
            align-self: end;
            margin-top: auto;
        }
        .s-wrapper {
            position: relative;
            max-width: 354px;
            label {
                position: absolute;
                top: -10px;
                left: 10px;
                background-color: #F9FAFB;
                color: #535257;
                display: block;
                width: 334px;
                padding-left: 5px;
            }
        }
        .s-wrapper-env {
            position: relative;
            max-width: 354px;
            label {
                background-color: #F9FAFB;
                color: #535257;
                width: 334px;
                padding-left: 10px;
            }
            div{
                margin-bottom: 10px;
            }
        }
        .r-wrapper {
            width: 100%;
            min-width: 100%;
            min-height: 220px;
            height: 300px;
            max-height: 300px;
            overflow-y: scroll;
        }
        .r-wrapper label[data-disable="disable"] {
            display: none;
        }
        .r-wrapper canvas{
            margin-right: 10px;
        }
        .r-wrapper .changeOuvertures{
            display: none;
        }
        .select {
            width: 100%;
            appearance: none;
            color: #535257;
            max-width: 354px;
            height: 56px;
            padding-left: 10px;
            padding-right: 15px;
            border-radius: 4px;
            background-color: #F9FAFB;
            border: 1px solid #8d8d8d;
            display: grid;
            grid-template-areas: "select";
            align-items: center;
        }
        .s-wrapper::after {
            content: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position-x: 100%;
            background-position-y: 5px;
            right: 10px;
            /*Adjust for position however you want*/

            top: 16px;
            padding: 0 0 2px;

            position: absolute;
            pointer-events: none;
        }
        /* The switch - the box around the slider */
        .switch {
            position: relative;
            display: inline-block;
            width: 47px;
            height: 20px;
        }

        /* Hide default HTML checkbox */
        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: -2px;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 28px;
            width: 26px;
            left: 0;
            bottom: -3px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked + .slider {
            background-color: #afbf07;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #afbf07;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
            filter: drop-shadow(0px 1px 1.5px rgba(0,1,1,0.25));
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }
    &--right {
        width: 50%;
        margin-left: 1rem;
        display: flex;
        justify-content: end;
        @media screen and (max-width: 1024px) {
            width: 100%;
            justify-content: center;
            margin-left: 0;
            margin-top: 1rem;
        }
        &.start {
            justify-content: flex-start;
        }
        .card-info {
            color: white;
            width: 494px;
            height: max-content;
            padding: 1rem 1.5rem 1.5rem;
            border-radius: 10px;
            filter: drop-shadow(0 0 4px rgba(0,1,1,0.25));
            background-color: #afbf07;
            background-image: url("/build/shop/images/card-big.svg");
            background-repeat: no-repeat;
            background-position-x: 90%;
            background-position-y: 175%;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            &--title {
                color: #ffffff;
                font-weight: 400;
                font-family: "Lato";
                text-transform: uppercase;
                margin-left: 1.5rem;
                margin-top: 14px;
            }
        }
        .three {
            height: 100%;
            width: 100%;
            max-width: 555px;
            img, canvas {
                max-width: 555px;
                width: 100%;
            }
        }
        .three-desc {
            padding-top: 0.25rem;
            img { display: block}
            p {
                text-align: left;
                color: white;
                font-size: 15px;
                font-weight: bolder;
                text-transform: uppercase;
                &.ttc {
                    font-size: 36px;
                    font-weight: bold;
                    display: inline-flex;
                    width: 100%;
                    justify-content: space-between;
                    align-content: center;
                }
            }
        }
        .cp {
            color: #FFFFFF;
            font-size: 12px;
            line-height: 1;
            margin-left: auto;
            font-weight: initial;
            height: fit-content;
            align-self: end;
            margin-bottom: 8px;
        }
        }
        .three-n {
            color: #FFFFFF;
            font-size: 12px;
            line-height: 1;
            margin-left: 1rem;
            display: inline-flex;
            width: 95%;
            justify-content: space-between;
        }
        .use-type {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 2.5rem;
            @media screen and (max-width: 1100px) {
                justify-content: center;
            }
        }
        .use-btn {
            display: flex;
            justify-content: end;
            margin-top: 2.5rem;
            width: 100%;
            a:nth-child(2) {
                margin-left: 0.75rem;
            }
            @media screen and (max-width: 1100px) {
                margin-right: 1.5rem;
            }
            @media screen and (max-width: 500px) {
                margin: 2.5rem auto 0 auto;
                flex-direction: column;
                width: 90%;
                text-align: center;
                a:nth-child(2) {
                    margin-left: 0;
                    margin-top: 0.75rem;
                }
            }
        }
        .gamme-type {
            display: flex;
            justify-content: end;
            flex-wrap: wrap;
            margin-top: 2.5rem;
            @media screen and (max-width: 1100px) {
                justify-content: center;
            }
        }
        .roof-type {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            /*margin: 2.5rem -1.75rem 0 -1.75rem;*/
            @media screen and (max-width: 1100px) {
                justify-content: center;
            }
        }
    }
    .sticky {
        position: sticky;
        top: 50px;
        height: 100%;
    }
    .vignette {
        margin: 5px !important;
        cursor: pointer;
    }
    .plans {
        padding: 2.5rem 0 0 1.75rem;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        h2 {
            color: #ffffff;
            font-weight: normal;
            text-transform: uppercase;
        }
        p {
            display: inline-block;
            font-size: 15px;
            color: #535257;
            span {
                color: #ffffff;
                font-weight: bold;
                font-size: larger;
                text-transform: uppercase;
            }
        }
        &--left {
            width: 50%;
            padding: 0 1.5rem 0.75rem 0.75rem;
            #validation {
                width: 100%;
                margin-top: 0.625rem;
            }
            #visualisation {
                width: 100%;
                margin-top: 0.625rem;
                text-align: center;
                display: inline-flex;
                justify-content: center;
            }
            #btn-modal-trigger {
                width: 100%;
                margin-top: 0.625rem;
            }
            #btnCommander {
                width: 100%;
                margin-top: 0.625rem;
            }
            #help {
                width: 100%;
            }
        }
        &--right {
            width: 50%;
            .card-info {
                color: white;
                width: 494px;
                height: 185px;
                padding: 1rem 1.5rem 1.5rem;
                border-radius: 10px;
                filter: drop-shadow(0 0 4px rgba(0,1,1,0.25));
                background-color: #afbf07;
                background-image: url("/build/shop/images/card-big.svg");
                background-repeat: no-repeat;
                background-position-x: 90%;
                background-position-y: 175%;
                &--title {
                    color: #ffffff;
                    font-weight: 400;
                    font-family: "Lato";
                    text-transform: uppercase;
                    margin-left: 1.5rem;
                    margin-top: 14px;
                }
            }
            .three {
                height: 80%;
                max-width: 555px;
                img {
                    max-width: 555px;
                    width: 100%;
                }
            }
            .three-desc {
                padding-top: 0.25rem;
                img { display: block}
                p {
                    text-align: left;
                    color: white;
                    font-size: 15px;
                    font-weight: bolder;
                    text-transform: uppercase;
                    &.ttc {
                        font-size: 36px;
                        font-weight: bold;
                    }
                }
            }
            .three-n {
                color: #FFFFFF;
                font-size: 12px;
                line-height: 1;
                margin-left: 1rem;
            }
        }
        &--nolog {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            padding-bottom: 50px;
            text-align: center;
        }
        @media screen and (max-width: 500px) {
            padding: 2.5rem 1rem 0 1rem;
        }
        @media screen and (max-width: 1024px) {
            flex-direction: column;
            &--left, &--right {
                width: 100%;
            }
        }
    }

.tabmenu {
    text-decoration: none;
    color: white;
    text-transform: uppercase;

}


.tabmenu:hover {
    color: white !important;
}


.active {
    padding-bottom: 12px;
    border-bottom: 8px #2d93ad solid;
}

/* Media query */
@media (max-width: 719px) {
    .app-flex {
        display: flex;
        flex-direction: column;

        .app-epingle {
            display: none;
        }

        .app-background {
            opacity: 0.5;
        }
    }
    .app-plus-container {
        height: 100% !important;
    }
    #app-header {
        .app-right-header {
            > div {
                > div {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
@media (max-width: 999px) {
    .app-hidden {
        display: none !important;
    }
}
@media (max-width: 1500px) {
    #app-header {
        .app-right-header {
            > div {
                > div {
                    margin-right: 20px;
                }
            }
        }
    }
}

@media (max-width: 1080px) {
    #app-header {
        display: none;
    }
    #burger {
        cursor: pointer;
    }
    .navbar {
        display: flex;
        height: 100px;
        justify-content: space-between;
        width: 100%;
        background-color: white;
        padding: 1rem 1.5rem;
        align-items: center;
        box-shadow: 0 0 6px 0 #777;
        position: relative;
        &__logo {
            max-width: 150px;
            margin-top: 0.25rem;
        }
        &__icons {
            display: flex;
            margin-left: auto;
            margin-right: 0.5rem;
            i.icon {
                font-size: 1.75em;
                color: #afbf07;
            }
        }
        .h, .w  {
            &-8 {
                height: 3rem;
                width: 3rem;
            }
        }
        &__links {
            position: absolute;
            top: 100px;
            right: 0;
            display: none;
            flex-direction: column;
            align-items: end;
            padding: 1.5rem;
            z-index: 99999;
            width: 100%;
            background-color: white;
            a, summary {
                font-size: 15px;
                font-family: Lato, sans-serif;
                text-decoration: none;
                color: #535257;
                margin: 0.5rem 0;
            }
            summary {
                font-weight: bold;
            }
            .icon {
                color: #afbf07;
            }
        }
    }
    .app-icon-floating {
        display: none;
    }

    .app-hidden-responsive {
        display: none !important;
    }
}

@media (min-width: 1080px) {
    .navbar {
        display: none;
    }
}
/* Checkout Wrapper*/
.address {
    width: 66.75% !important;
}
.checkout__wrapper {
    .ui.grid {
        margin-top: initial;
        margin-bottom: initial;
        margin-left: initial;
        margin-right: initial;
    }
    #sylius-payment-methods, #sylius-shipping-methods {
        margin-right: 1rem;
    }
    .ui.two.column.stackable.grid {
        justify-content: space-between;
        > div {
            width: 49%;
        }
    }

    .ui.form .message:first-child {
        height: 100%;
    }
}

/* Form */

form {
    h1 {
        font-family: Caveat, sans-serif !important;
        color: #afbf07 !important;
    }
    h3 {
        font-family: Caveat, sans-serif !important;
        color: #afbf07 !important;
        &.ui.header {
            font-size: 2rem !important;
        }
    }
    button {
        background-color: #afbf07;
    }
    .field > label {
        font-weight: initial !important;
        color: #535257 !important;
    }
}

form[name="configurateur"] {
    button {
        background-color: #b9b8bd;
    }
    h3.card-info--title {
        color: #FFFFFF !important;
        font-size: 24px;
        font-weight: bold;
        font-family: "Lato", sans-serif !important;
    }
}

/* Utility */

.grid-alt {
    margin: 0 !important;
}

.flex {
    display: flex;
    &-col {
        flex-direction: column !important;
    }
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.h-5 {
    height: 1.25rem;
}

.w-full {
    width: 100%;
}

.w-5 {
    width: 1.25rem;
}

.mx-6 {
    margin: 0 1.5rem;
}

.mx-auto {
    margin:  auto;
}

.-mx-10 {
    margin: 0 -1.75rem;
}

.-ml-10 {
    margin-left: -1.75rem;
}

.ml-10 {
    margin-left: 1.75rem;
}

.mt-8 {
    margin-top: 1rem;
}

.mb-8 {
    margin-bottom: 4rem;
}

.mb-6 {
    margin-bottom: 1.5rem;
}

.mt-10 {
    margin-top: 1.5rem;
}

.mt-12 {
    margin-top: 2.5rem;
}

.mb-12 {
    margin-bottom: 2.5rem;
}

.mt-16 {
    margin-top: 4rem;
}

.ml-3 {
    margin-left: 0.75rem;
}

.mx-2 {
    margin: 0 0.5rem;
}

.mx-10 {
    margin: 0 1.5rem;
}

.pointer {
    cursor: pointer;
}
.p-4 {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.bird-r {
    &:after {
        display: block;
        position: absolute;
        top: 0;
        right: -8rem;
        content: ' ';
        background-image: url('/build/shop/images/origami_banner.svg');
        background-repeat: no-repeat;
        overflow: visible;
        height: 79px;
        width: 68px;
    }
    @media screen and (max-width: 480px) {
        display: none;
    }
}
 /* Contact Page */

.contact {
    margin: 25px 0;
    &__title {
        font-size: 2.5rem;
        color: #afbf07;
        margin-bottom: 0.5rem;
    }
}
/* Override */

#sylius-api-login-submit {
    background-color: #afbf07 !important;
}

.ui.primary.button, .ui.primary.buttons .button {
    background-color: #afbf07 !important;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

/* Modal */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: var(--black);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease-in;
    z-index: 31051991
}

.modal.is-visible {
    visibility: visible;
    opacity: 1;
}

.modal-dialog {
    position: relative;
    max-width: 800px;
    max-height: 80vh;
    border-radius: 5px;
    background: white;
    overflow: auto;
    cursor: default;
    padding: 1rem;
    -webkit-box-shadow: 4px 5px 15px 5px rgba(0,0,0,0.65);
    box-shadow: 4px 5px 15px 5px rgba(0,0,0,0.65);
}

/*CUSTOM VARIABLES HERE*/

.modal-dialog > * {
    padding: 1rem;
}

.modal-content li {
        list-style-type: disc !important;
        font-size: 15px;
        color: #535257;
}

.modal-content h2 {
    margin-top: 0 !important;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
        all: initial;
        cursor: pointer;
        background-color: transparent !important;
    }
    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.modal-header .modal-close {
    font-size: 1.5rem;
}

.modal p + p {
    margin-top: 1rem;
}

.modal-img {
    display: flex;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 480px) {
        flex-direction: column
    }
    img {
        display: block;
        margin-right: 1rem;
    }
}

/* related articles */
.app-conseils-list {
    margin-bottom: 2rem;
    .product__similar {
        font-size: 36px;
        color: #afbf07;
        font-weight: 600;
        font-family: "Caveat";
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 4rem;
        width: fit-content;
    }
    .app-box-container {
        gap: 10px;
        justify-content: start;
        .app-small-box {
            padding: 25px;
            position: relative;
            width: 100%;
            height: 280px;
            max-width: 270px;
            background-color: #926e50 !important;
            hr {
                margin: 10px 0 5px 0;
            }
            .header-card {
                font-size: 20px;
            }
            .app-background-image {
                position: absolute;
                top: auto;
                bottom: 10px;
                left: 10px;
                right: 10px;
                &.thumbnail {
                    display: block;
                    width: 250px;
                    height: 180px;
                }
            }
        }
        @media screen and (max-width: 1024px) {
            justify-content: center;
        }
    }
    @media screen and (max-width: 480px) {
        .bird-r {
            display: flex !important;
        }
    }
}

.actu-flex {
    gap: 10px;
}

@media screen and (max-width: 750px) {
    .app-page-conseils-technique {
        table {
            tr {
                flex-direction: column;
            }
            td {
                margin: 0 auto !important;
            }
        }
    }
}

/* modale adresse */
.modal-address-container,
.modal-configurateur-container,
.modal-configurateur-container2,
.modal-configurateur-container3,
.modal-configurateur-container4,
.modal-configurateur-containerNoFacade,
.modal-configurateur-container-estimate-expired,
.modal-configurateur-containerNoWindow,
.modal-configurateur-containerChoseWindow,
.modal-configurateur-containerChoseDoor,
.modal-configurateur-groundSurfaceAlert
{
    z-index: 6;
    visibility: hidden;
    transition: 0.4s;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;

    h1 {
        color: #afbf07;
    }

    p {
        text-align: center;
    }
    p.text-start {
        text-align: start;
    }

}

.modal-address-container.active,
.modal-configurateur-container.active,
.modal-configurateur-container2.active,
.modal-configurateur-container3.active,
.modal-configurateur-container4.active,
.modal-configurateur-containerNoFacade.active,
.modal-configurateur-containerNoWindow.active,
.modal-configurateur-containerChoseWindow.active,
.modal-configurateur-containerChoseDoor.active,
.modal-configurateur-container-estimate-expired.active,
.modal-configurateur-groundSurfaceAlert.active
{
    /* display: block; */
    visibility: visible;
}

.modal-div-button {
    margin-top: 20px;
    float: right;
}

.modal-window {
    opacity: 0;
    width: 95%;
    max-width: 700px;
    min-width: 300px;
    padding: 30px;
    border-radius: 5px;
    border: 5px solid #afbf07;
    background: #fff;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,calc(-50% - 50px));
    transition: opacity 0.4s ease-out,
    transform 0.4s ease-out;

}

.modal-address-container.active .modal-window,
.modal-configurateur-container.active .modal-window,
.modal-configurateur-container2.active .modal-window,
.modal-configurateur-container3.active .modal-window,
.modal-configurateur-container4.active .modal-window,
.modal-configurateur-containerNoFacade.active .modal-window,
.modal-configurateur-containerNoWindow.active .modal-window,
.modal-configurateur-containerChoseWindow.active .modal-window,
.modal-configurateur-containerChoseDoor.active .modal-window,
.modal-configurateur-groundSurfaceAlert.active .modal-window,
.modal-configurateur-container-estimate-expired.active .modal-window
{
    opacity: 1;
    transform: translate(-50%,-50%);
    transition: opacity 0.4s 0.2s ease-out,
    transform 0.4s 0.2s ease-out;
}
.overlay {
    opacity: 0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-color: #333333d3;
    transition: opacity 0.4s ease-out;
}

.modal-address-container.active .overlay,
.modal-configurateur-container.active .overlay,
.modal-configurateur-container2.active .overlay,
.modal-configurateur-container3.active .overlay,
.modal-configurateur-container4.active .overlay,
.modal-configurateur-containerNoFacade.active .overlay,
.modal-configurateur-containerNoWindow.active .overlay,
.modal-configurateur-containerChoseWindow.active .overlay,
.modal-configurateur-containerChoseDoor.active .overlay,
.modal-configurateur-groundSurfaceAlert.active .overlay,
.modal-configurateur-container-estimate-expired.active .overlay
{
    opacity: 1;
    transition: opacity 0.4s 0.2s ease-out;
}

div.TDloadingContainer {
    visibility: hidden;
    position: fixed;
    top: 0;
    width:100vw;
    height: 100vh;
    background: rgb(0.2,0.2,0.2);
    opacity: 0;
    color: #ffffff;
    font-size: 30px;
}

div.TDloadingContainer.active {
    visibility: visible;
    pointer-events: all;
    z-index: 9999;
    position: fixed;
    top: 0;
    width:100vw;
    height: 100vh;
    opacity: 0.6;
    transition: opacity 0.4s 0.2s ease-out;
}

div.TDloadingContainer div{
    position: fixed;
    top: 50%;
    left: 45%;
}

.display-button-address {
    visibility: hidden;
}

.disabled {
    pointer-events: none;
    cursor: default;
}

.text-start {
    text-align: start;
}

div.ui.toggle.checkbox:after {
    content: "";
    color: transparent;
    display: none;
}

@media screen and (max-width: 440px) {
    .app-page-conseils-technique {
        .app-small-box {
            display: flex;
            flex-direction: column;
            flex: initial;
            .app-background-image {
                position: initial !important;
                margin-left: -10px !important;
            }
        }
    }
}

/* CTA */
.cta-b {
    background-image: url('/build/shop/images/expositionabri.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #afbf07;
    height: 300px;
    color: white;
    .title {
        font-family: "caveat";
        font-size: 36px;
    }
    .normal {
        font-family: initial;
        margin-top: 0.75rem;
    }
}


